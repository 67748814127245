import { clearAccessToken } from "../auth/authManager";

// src/reducers/authReducer.js
const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  isQRScannerVisible: false,
  isContactButtonVisible: false,
  isSubscribeAlertVisible: false,
  isCancelSubscriptionAlertVisible: false,
  subscriptionAlertType: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
    case "AUTH_CHECK_START":
      return { ...state, loading: true, error: null };

    case "LOGIN_SUCCESS":
    case "AUTH_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...action.payload.user,
          subscription: action.payload.user.subscription || false,
          square_subscribe_id: action.payload.user.square_subscribe_id || null,
          square_customer_id: action.payload.user.square_customer_id || null,
          square_invoice_id: action.payload.user.square_invoice_id || null,
          square_order_id: action.payload.user.square_order_id || null,
          subscription_day: action.payload.user.subscription_day || null,
          square_subscribe_canceledDate:
            action.payload.user.square_subscribe_canceledDate || null,
        },
        loading: false,
        error: null,
      };

    case "LOGIN_FAIL":
    case "AUTH_FAIL":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: action.payload,
      };

    case "LOGOUT":
      clearAccessToken();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: null,
      };

    case "POINT_CHANGE":
      return {
        ...state,
        user: {
          ...state.user,
          point: action.payload.updatedPoint,
          activities: [...state.user.activities, action.payload.activity],
        },
      };

    case "ACTIVITY_SAVE":
      return {
        ...state,
        user: {
          ...state.user,
          activities: [...state.user.activities, action.payload.activity],
        },
      };

    case "REGISTER_REQUEST":
      return { ...state, loading: true, error: null };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };

    case "REGISTER_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "COUPON_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          point: action.payload.updatedPoints,
          rewardActivities: [
            ...state.user.rewardActivities,
            action.payload.redeemedReward,
          ],
        },
      };
    case "COUPON_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "TOGGLE_QR_SCANNER":
      return {
        ...state,
        isQRScannerVisible: !state.isQRScannerVisible,
      };

    // src/reducers/authReducer.js
    case "SET_IDENTIFY_INFO":
      return {
        ...state,
        identifyInfo: action.payload,
      };

    case "TOGGLE_CONTACT_BUTTON":
      return {
        ...state,
        isContactButtonVisible: !state.isContactButtonVisible,
      };

    case "TOGGLE_SUBSCRIBE_ALERT":
      return {
        ...state,
        isSubscribeAlertVisible: !state.isSubscribeAlertVisible,
        subscriptionAlertType: "subscribe",
      };
    case "TOGGLE_CANCEL_SUBSCRIPTION_ALERT":
      return {
        ...state,
        isCancelSubscriptionAlertVisible:
          !state.isCancelSubscriptionAlertVisible,
        subscriptionAlertType: "cancel",
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.field]: action.payload.value,
        },
      };

    case "USER_ALL_INFO_UPDATE":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
      
    default:
      return state;
  }
};

export default authReducer;

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  logout,
  toggleContactButton,
  toggleCancelSubscriptionAlert,
} from "../actions/authActions";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const changeDate = (date) => {
    const allDate = date ? date.toString().split("-") : "";

    const year = allDate[0];
    const month = allDate[1];
    return `${year}-${month}`;
  };

  const handleSubscription = () => {
    dispatch(toggleCancelSubscriptionAlert());
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
    closeMenu();
  };

  const handleLogin = () => {
    navigate("/login");
    closeMenu();
  };

  const toggleMenu = () => {
    if (isMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const openMenu = () => {
    setIsMenuOpen(true);
    setIsAnimating(true);
    setTimeout(() => {
      menuRef.current?.classList.add("dropdown-menu-enter");
    }, 10);
  };

  const closeMenu = () => {
    setIsAnimating(true);
    menuRef.current?.classList.remove("dropdown-menu-enter");
    menuRef.current?.classList.add("dropdown-menu-exit");
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsAnimating(false);
    }, 150);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // const showBackButton =
  //   !["/login", "/", "/terms-of-service"].includes(location.pathname) &&
  //   !location.pathname.startsWith("/verify-email/");
  const showHeaderLogo = ["/login", "/", "/register"].includes(
    location.pathname
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        isMenuOpen
      ) {
        closeMenu();
      }
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape" && isMenuOpen) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isMenuOpen]);

  return (
    <header className="bg-transparent">
      <div className="header-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <button
          onClick={handleGoBack}
          className="back-button text-gray-600 hover:text-gray-900 transition-colors duration-200"
        >
          <i className="fas fa-chevron-left text-xl"></i>
        </button>

        {showHeaderLogo && (
          <div className="logo-container flex-1 flex justify-center">
            <img
              className="logo h-8 w-auto animate-fadeInRise transition-all duration-300 ease-in-out hover:opacity-80"
              src="/our_logo.png"
              alt="Company Logo"
            />
          </div>
        )}

        <div className="menu-container relative">
          <>
            <i
              className="fas fa-bars menu-icon text-2xl cursor-pointer hover:text-gray-700 transition-colors duration-200"
              onClick={toggleMenu}
              role="button"
              tabIndex="0"
              aria-label="Menu"
            ></i>
            {(isMenuOpen || isAnimating) && (
              <div
                className="dropdown-menu fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-2xl flex flex-col h-full"
                ref={menuRef}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex flex-col h-full">
                  <div className="user-info p-6 text-black text-center">
                    <h2 className=" text-4xl sm:text-5xl font-bold my-8 sm:my-10 text-[#FF4136]">
                      CERAGEM
                    </h2>
                    <h2 className="text-2xl font-semibold mb-2">
                      Welcome, {user?.name || "Guest"}
                    </h2>
                    <p className="text-sm mb-4">
                    {user && user.createdAt && (`Member since ${user.createdAt}`)}
                    {/* Member since {user ? changeDate(user?.createdAt) : "2023"} */}
                    </p>
                  </div>

                  <div className="flex-grow bg-white p-6 overflow-y-auto">
                    <h3 className="text-xl font-semibold mb-4 text-gray-800">
                      Subscription Status
                    </h3>
                    <div className="space-y-3">
                      {[
                        {
                          icon: "calendar-alt",
                          label: "Start Date",
                          value: user?.subscription_day?.toLocaleString() ?? "N/A",
                        },
                        {
                          icon: "ban",
                          label: "cancellation date",
                          value: user?.square_subscribe_canceledDate.toLocaleString() ?? "N/A"
                        },

                        {
                          icon: "heartbeat",
                          label: "Tier",
                          value: `${user ? user.tier ?? "N/A" : "N/A"}`,
                        },

                        {
                          icon: "gift",
                          label: "Point",
                          value: `${user ? user.point ?? 0 : 0}`,
                        },
                      ].map((item, index) => (
                        <div
                          key={index}
                          className="bg-gray-100 p-3 rounded-lg flex items-center"
                        >
                          <i
                            className={`fas fa-${item.icon} text-lg text-[#5851DB] mr-3`}
                          ></i>
                          <div>
                            <p className="text-xs text-gray-500 uppercase">
                              {item.label}
                            </p>
                            <p className="text-sm font-medium text-gray-800">
                              {item.value}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="mt-6">
                      {user ? (
                        // User is logged in, now check subscription
                        !user.subscription ? (
                          <Link
                            to="/subscription"
                            className="w-full bg-[#5851DB] text-white py-3 px-4 rounded-lg font-medium text-center block hover:bg-[#4a45b3] transition-colors duration-200"
                            onClick={closeMenu}
                          >
                            Go to Subscribe
                          </Link>
                        ) : (
                          <button
                            className="w-full bg-[#5851DB] text-white py-3 px-4 rounded-lg font-medium text-center block hover:bg-[#4a45b3] transition-colors duration-200"
                            onClick={handleSubscription}
                          >
                            Cancel Subscription
                          </button>
                        )
                      ) : (
                        // User is not logged in
                        <Link
                          to="/login"
                          className="w-full bg-[#5851DB] text-white py-3 px-4 rounded-lg font-medium text-center block hover:bg-[#4a45b3] transition-colors duration-200"
                          onClick={closeMenu}
                        >
                          Login to Subscribe
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className="flex my-2 sm:m-0 justify-between items-center p-4 bg-white border-t border-gray-200">
                    <button
                      onClick={isAuthenticated ? handleLogout : handleLogin}
                      className="text-[#FF4136] hover:text-[#e63a30] transition-colors duration-200 font-medium"
                    >
                      {isAuthenticated ? "Logout" : "LogIn"}
                    </button>
                    <button
                      className="text-[#5851DB] hover:text-[#4a45b3] transition-colors duration-200 font-medium"
                      onClick={() => {
                        dispatch(toggleContactButton());
                      }}
                    >
                      Help Center
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </header>
  );
}

export default Header;

import api from "../utils/api";
import { useSelector } from "react-redux";

import {
  getAccessToken,
  setAccessToken,
  clearAccessToken,
} from "../auth/authManager.js";
// import { TypeIcon } from "lucide-react";

export const login = (credentials) => async (dispatch) => {
  dispatch({ type: "LOGIN_REQUEST" });
  try {
    // console.log("Attempting login with:", credentials);
    const res = await api.post("/auth/login", credentials);
    // console.log("Server response:", res);

    // ? status 400이면 바로 error로 넘어오는거 가튼데
    // ? 프론트가 400 받았을때

    if (res.data && res.data.token && res.status === 200) {
      setAccessToken(res.data.token);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      });
      return { type: "LOGIN_SUCCESS" };
    } else {
      console.error("Unexpected response structure:", res.data);
      return { type: "LOGIN_FAIL" };
      // if(res.data.type === "NON_MEMBER"){
      //   throw new Error(res.data);
      // }

      // throw new Error(res.data.msg);
    }
  } catch (err) {
    console.error("Login error:", err);
    if (err.response?.data?.type === "NON_MEMBER") {
      dispatch({
        type: "NON_MEMBER",
        payload: err.response.data,
      });
      return {
        type: err.response.data.type,
        msg: err.response.data.msg,
      };
    }

    dispatch({
      type: "LOGIN_FAIL",
      payload: err.response?.data?.msg || "An error occurred during login",
    });
    return {
      type: "LOGIN_FAIL",
      msg: err.response?.data?.msg || "Login failed",
    };
  }
};

export const checkAuth = () => async (dispatch) => {
  const token = getAccessToken();

  if (!token) {
    dispatch({ type: "AUTH_FAIL" });
    return;
  }

  dispatch({ type: "AUTH_CHECK_START" });

  try {
    const res = await api.get("/auth/verify", {
      headers: {
        "x-auth-token": token,
      },
    });
    if (res.data) {
      dispatch({
        type: "AUTH_SUCCESS",
        payload: { user: res.data, token },
      });
    } else {
      throw new Error("Invalid response from server");
    }
  } catch (err) {
    console.error("Auth check error:", err);
    dispatch({ type: "AUTH_FAIL" });
    clearAccessToken();
  }
};

export const logout = () => async (dispatch) => {
  try {
    // Call the logout endpoint
    await api.post("/auth/logout");
    clearAccessToken();
    // Dispatch logout action to update Redux state
    dispatch({ type: "LOGOUT" });

    // Clear access token from local storage or memory

    // or
    // clearAccessToken(); // If you're using a custom function to manage the token in memory

    // Optionally, redirect to login page
    // history.push('/login');
  } catch (error) {
    console.error("Logout error:", error);
    // Even if the server request fails, we still want to clear local state
    dispatch({ type: "LOGOUT" });
    clearAccessToken();
  }
};

export const qrDataSave = (data) => async (dispatch) => {
  // qrDate 같은거는 뭐 dispatch 해서 상태관리할 필요가 있나?
  // pointDataGet 할 떄는 필요할거 같긴 한데 일단 보류
  // ! data는 user객체랑, qrData 다 넘어옴
  try {
    const res = await api.post("/qrcode/save-point", data);

    // console.log(JSON.stringify(res));
    // data이거 체인징된 포인트임. userUpdatePoint
    if (res.status === 200) {
      await dispatch({ type: "POINT_CHANGE", payload: res.data });
      // !아래로 리턴 하는건 그냥 포인트 얼마나 차감됐나 보여주려고 쓴거고, 위에는 업데이트 포인트랑 액티비티 보내는거, 근데 어차피 완료나면 창 자동으로 꺼지는데 할 필요 없을듯
      // return {type:"POINT_CHANGE"};

      return {
        type: "POINT_CHANGE",
        payload: {
          updatedPoint: res.data.updatedPoint,
          qrPoint: res.data.qrPoint,
        },
      };
    }
  } catch (error) {
    console.error("qr데이터 저장 에러뜸:" + error);
    return { type: "SAVE_FAIL", message: error.response?.data?.message };
  }
};

export const qrPasswordCheck = (encryptedPassword) => async (dispatch) => {
  try {
    // console.log("encryptedPassword:"+encryptedPassword);
    const res = await api.post("/qrcode/password", {
      password: encryptedPassword,
    });
    // console.log("res:"+res.status);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // console.log("qr패스워드 체크 액션 오류 발생");
    console.error(error);
    return false;
  }
};

export const sendEmailAction = (emailData) => async () => {
  try {
    let response = await api.post("/reservation/send", emailData);

    return response.status;
    // status 200 반환
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reserveSchedule = (reservationData) => async (dispatch) => {
  try {
    // console.log('예약정보 보내온거:'+JSON.stringify(reservationData));
    const res = await api.post("/reservation/calendar", reservationData);
    // console.log('res'+res);
    // console.log('res'+JSON.stringify(res));
    // console.log('예약 요청 결과:'+res);
    
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({ status: 200 }); // Simulate successful API response
    //   }, 1000);
    // });
    return res;
  } catch (error) {
    // console.error('예약 요청 에러:'+JSON.stringify(error.response.data));
    // console.error('예약 요청 에러:'+error.response.status);
    // console.error('예약 요청 에러:'+JSON.stringify(error.response));
    return error.response;
  }
};

// FIXME: 이거 나중에 고쳐야함. 그냥 로그인 할 때 activity 받아오도록 하는게 훠어어어얼씬 나을듯. 다 바꿧고 이거든 사용 잘 안할거 같은데 혹시 모르니까 남겨두자. 지금 이거 사용 안하는중 9월 21일.
// export const fetchUserProfile = (user) => async (dispatch) => {
//   try {
//     const res = await api.get('/profile');
//     // console.log('fetchUserProfile실행');
//     // dispatch({
//     //   type: 'USER_PROFILE_LOADED',
//     //   payload: res.data
//     // });
//   } catch (err) {
//     console.error('Error fetching user profile:', err);
//   }
// };

// TODO: 안쓰는거 같은데 일단 지워둠
// export const fetchAllActivities = () => async (dispatch) => {
//   try {
//     const res = await api.get('/profile/activities');
//     // console.log('fetchAllActivities 실행');
//     return res.data;
//   } catch (err) {
//     console.error('Error fetching all activities:', err);
//     return [];
//   }
// };

export const register = (userData) => async (dispatch) => {
  dispatch({ type: "REGISTER_REQUEST" });
  try {
    // console.log(JSON.stringify(userData));
    const res = await api.post("/auth/register", userData);
    // console.log('등록 왜 오류:', res);
    dispatch({
      type: "REGISTER_SUCCESS",
      payload: res.data,
    });
    return { type: "REGISTER_SUCCESS" };
  } catch (err) {
    // console.error("에러 왜오류:"+err);
    dispatch({
      type: "REGISTER_FAIL",
      payload: err.response?.data?.msg || "Registration failed",
    });
    return { type: "REGISTER_FAIL", status: err.response.status };
  }
};

export const changePassword = (passwordData) => async (dispatch) => {
  try {
    const res = await api.put("/auth/change-password", passwordData);

    return res.status;
  } catch (err) {
    throw err;
  }
};

// () => {} 이렇게만 하면 비동기 처리 안돼서 promise 에러뜸, api 요청 못한다는거임

// todo: 고객 생성, 카드 주문 생성, 카드 결제, 구독 주문 생성, 구독 생성

export const payment = (paymentData) => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    if (!user) {
      throw new Error('User not authenticated');
    }

    const res = await api.post("/payment/process", paymentData);

    if (res.status === 200 && res.data.success) {
      // Update user information after successful payment
      dispatch({
        type: "USER_ALL_INFO_UPDATE",
        payload: {
          subscription: true,
          subscription_day: new Date().toISOString(),
          tier: "Wellness",
          point: (user.point || 0) + 200,
          activities: [
            ...(user.activities || []),
            {
              type: "subscription",
              details: "Membership subscription activated",
              date: new Date().toISOString()
            }
          ], 
          square_subscribe_canceledDate: null // Reset canceled date if it exists
        }
      });

      return {
        type: "PAYMENT_SUCCESS",
        msg: "Payment processed successfully"
      };
    }


  } catch (error) {
    console.error('Payment error:', error);
    
    // Dispatch error to update state
    dispatch({
      type: "LOGIN_FAIL", // Using existing error handling from your reducer
      payload: error.response?.data?.error?.message || error.message || "Payment processing failed"
    });

    return {
      type: "PAYMENT_ERROR",
      msg: error.response?.data?.error?.message || error.message || "Payment processing failed"
    };
  }
};



export const beforePaymentLogic = (userData) => async () => {
  try {
    const res = await api.post("/subscription/create-customer",userData);

    if(res.status === 200){
      // todo: 스퀘어 내에서 고객 체크 및 생성, 주문 생성까지 완료.
      // todo: 반환할게 있는가 ? 그냥 데이터 업데이트나 할까
    }
    
    return {status: res.status};

  } catch (error) {
    return error.response.status;

  }
}


export const subscribe = (userData) => async (dispatch) => {
  try {
    const res = await api.post("/subscription", userData);

    if (res.data.type === "SUBSCRIBE_SUCCESS") {
      // Update user state with Square IDs from the response
      dispatch({
        type: "USER_ALL_INFO_UPDATE",
        payload: {
          square_invoice_id: res.data.user.square_invoice_id,
          square_customer_id: res.data.user.square_customer_id,
          square_order_id: res.data.user.square_order_id,
        },
      });

      return { type: res.data.type, msg: res.data.msg };
    }

    return { type: res.data.type, msg: res.data.msg };
  } catch (error) {
    return {
      type: error.response?.data?.type || "SUBSCRIBE_ERROR",
      msg: error.response?.data?.msg || "Subscription request failed",
    };
  }
};

export const subscribe_check = (user) => async (dispatch) => {
  try {
    if (!user?.email) {
      console.error("No user email found for subscription check");
      return;
    }

    const res = await api.post("/subscription/check", { email: user.email });

    if (res.status === 200) {
      dispatch({
        type: "USER_ALL_INFO_UPDATE",
        payload: res.data.user,
      });
    }
  } catch (error) {
    console.error("Subscription check error:", error);
    // return false;
  }
};
export const findId = (userData) => async () => {
  // ? Data = name, phoneNumber
  try {
    // console.log('유저 데이터:'+JSON.stringify(userData));
    const res = await api.post("/profile/find-id", userData);
    // console.log(res);
    // console.log(res.data.status);
    // console.log(res.status);
    // console.log('아이디 찾기 결과:'+JSON.stringify(res.data));
    // console.log('status:'+ res.status);
    // console.log('email:'+ res.data.email);

    return { status: res.status, email: res.data.email };
  } catch (error) {
    // console.log('아이디 찾기 에러용:'+error);
    return error;
  }
};

export const sendResetPasswordEmail = (emailData) => async () => {
  try {
    const response = await api.post("/auth/send/reset-password", emailData);
    return response.status;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resetPassword = (resetData) => async () => {
  try {
    const response = await api.post("/auth/reset-password", resetData);
    return response.status;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelSubscription = (userCredentials) => async (dispatch) => {
  try {
    const response = await api.post("/subscription/cancel", userCredentials);

    // console.log('구독 취소 결과:'+ response.data);
    if (response.status === 200) {
      dispatch(
        updateUser(response.data.userData)
      );
      return { success: true, msg: response.data.msg };
    } else {
      dispatch(
        updateUser(response.data.userData)
      );
      return { success: false, msg: response.data.msg };
    }
    // ! 아니네 ㅋㅋ status 받는 곳 어디던, 200이 아닌 400 받으면 error로 빠지는거 같음.
  } catch (error) {
    // console.log('구독 취소 액션 에러:');
    // console.log('구독 취소 액션 에러:'+JSON.stringify(error.response));
    // console.log('값 전송');
    // console.log(error.response.data.userData);
    dispatch(
      updateUser(error.response.data.userData)
    );
    return error.response.data;
  }
};

// TODO: type 보내는 곳만 정리

export const toggleQRScanner = () => ({
  type: "TOGGLE_QR_SCANNER",
});

// * 이거 qr 성공했을 때 정보 확인하는거

export const setIdentifyInfo = (info) => ({
  type: "SET_IDENTIFY_INFO",
  payload: info,
});

export const toggleContactButton = () => ({
  type: "TOGGLE_CONTACT_BUTTON",
});

export const toggleSubscribeAlert = () => ({
  type: "TOGGLE_SUBSCRIBE_ALERT",
});

export const toggleCancelSubscriptionAlert = () => ({
  type: "TOGGLE_CANCEL_SUBSCRIPTION_ALERT",
});

export const updateUser = (field, value) => ({
  type: "UPDATE_USER",
  payload: { field, value },
});

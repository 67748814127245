import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import { reserveSchedule } from "../actions/authActions";
import { phoneNumberRegex, emailRegex, openTerms } from "./Subscription";
import ReactGA from "react-ga4";

const FullertonReservation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedService] = useState("Health Consulting");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState("");
  const [phone, setPhone] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [request, setRequest] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const isAdvertisement = "false";

  const user = useSelector((state) => state.auth.user);
  const fullertonStore = {
    id: "3",
    name: "FULLERTON",
    email: "fullerton@ceragemus.com",
  };

  const getReferrerInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      source: urlParams.get("utm_source") || "direct",
      medium: urlParams.get("utm_medium") || "",
      campaign: urlParams.get("utm_campaign") || "",
      raw_referrer: document.referrer || "direct",
      full_url: window.location.href,
      landing_page: window.location.pathname + window.location.search,
    };
  };

  const initializeGA4 = () => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-4L9JX65TFT", {
        gaOptions: {
          cookieFlags: "SameSite=None;Secure",
          storage: "none",
          storeGac: false,
        },
        gtagOptions: {
          send_page_view: false,
        },
        debug: true,
      });
      const referrerInfo = getReferrerInfo();
      ReactGA.event({
        category: "User Acquisition",
        action: "Page Load",
        label: referrerInfo.source,
        referrer_info: referrerInfo,
        timestamp: new Date().toISOString(),
      });

      console.log("Referrer information:", referrerInfo); // 디
    }
  };

  const trackButtonClick = (buttonName, additionalData = {}) => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Button Interaction",
        action: "Click",
        label: buttonName,
        ...additionalData,
      });
      console.log(`Button clicked: ${buttonName}`, additionalData); // 디버깅용
    }
  };

  const trackPageView = () => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: document.title,
        location: window.location.href,
        referrer: document.referrer || "direct",
      });
    }
  };

  const trackReservationAttempt = (
    reservationData,
    success,
    errorMessage = null
  ) => {
    if (process.env.NODE_ENV === "production") {
      const referrerInfo = getReferrerInfo();

      // 페이지 로드 시간 계산을 위한 현대적인 방법
      const getPageLoadTime = () => {
        if (window.performance && window.performance.getEntriesByType) {
          const perfEntries = window.performance.getEntriesByType("navigation");
          if (perfEntries.length > 0) {
            return Math.round(perfEntries[0].loadEventEnd);
          }
        }
        return undefined;
      };

      ReactGA.event({
        category: "Reservation",
        action: success ? "Submit Success" : "Submit Failure",
        label: reservationData.store,
        value: success ? 1 : 0,
        nonInteraction: false,
        service_type: reservationData.service,
        reservation_date: reservationData.reservation_date,
        reservation_time: reservationData.reservation_time,
        referrer: document.referrer || "direct",
        error_message: errorMessage,
        user_type: reservationData.email ? "new" : "returning",
        device_type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
          navigator.userAgent
        )
          ? "mobile"
          : "desktop",
        page_load_time: getPageLoadTime(),
        timestamp: new Date().toISOString(), // 타임스탬프 추가
        viewport_size: `${window.innerWidth}x${window.innerHeight}`, // 뷰포트 크기 추가
        traffic_source: referrerInfo.source,
        traffic_medium: referrerInfo.medium,
        traffic_campaign: referrerInfo.campaign,
        raw_referrer: referrerInfo.raw_referrer,
        landing_page: referrerInfo.landing_page,
      });

      // 디버깅용 콘솔 로그
      console.log("Reservation attempt tracked:", {
        success,
        store: reservationData.store,
        service: reservationData.service,
        error: errorMessage,
      });
    }
  };

  const mountTime = Date.now();

  // ? 일단은 11월만 보이게
  // Available dates
  const availableDates = [
    new Date(2024, 10, 8), // Nov 8
    new Date(2024, 10, 12), // Nov 12
    new Date(2024, 10, 21), // Nov 21
    // new Date(2024, 11, 3), // Dec 3
    // new Date(2024, 11, 5), // Dec 5
    // new Date(2024, 11, 10), // Dec 10
    // new Date(2024, 11, 12), // Dec 12
    // new Date(2024, 11, 17), // Dec 17
    // new Date(2024, 11, 19), // Dec 19
  ];

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");
    if (phoneNumber.length <= 3) return phoneNumber;
    if (phoneNumber.length <= 6)
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const convertTo12Hour = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.name);
      setPhone(user.phoneNumber);
    }
  }, [user]);

  useEffect(() => {
    initializeGA4();
    trackPageView();

    return () => {
      if (process.env.NODE_ENV === "production") {
        ReactGA.event({
          category: "User Engagement",
          action: "Leave Reservation Page",
          label: "Time Spent",
          value: Math.floor((Date.now() - mountTime) / 1000),
        });
      }
    };
  }, []);

  useEffect(() => {
    if (date) {
      const times = [];
      // Generate times from 10 AM to 5 PM, excluding 12-1 PM
      for (let hour = 10; hour <= 17; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          // Skip lunch hour (12-1 PM)
          if (hour === 12) continue;

          if (hour === 17 && minute === 0) break;

          const time24 = `${hour.toString().padStart(2, "0")}:${minute
            .toString()
            .padStart(2, "0")}`;
          times.push(time24);
        }
      }
      setAvailableTimes(times);
    }
  }, [date]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    setTime(""); // Reset time when date changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let reservationData;

    if (!emailRegex.test(email)) {
      setReservationStatus("Please enter a valid email address");
      return;
    }

    if (!date) {
      setReservationStatus("Please select a date");
      return;
    }

    if (!time) {
      setReservationStatus("Please select a time");
      return;
    }

    if (!name || name.trim().length === 0) {
      setReservationStatus("Please enter your name");
      return;
    }

    if (!agreedToTerms) {
      setReservationStatus("Please agree to the terms and conditions");
      return;
    }

    if (!phoneNumberRegex.test(phone)) {
      setReservationStatus("The PhoneNumber format must be '111-111-1111'");
      return;
    }

    setIsSubmitting(true);

    try {
      const dateString = date.toISOString().split("T")[0];
      reservationData = {
        email,
        name,
        phone,
        store: fullertonStore.name,
        service: selectedService,
        store_email: fullertonStore.email,
        reservation_date: dateString,
        reservation_time: time,
        comments: request,
        agreedToTerms,
        isAdvertisement,
      };

      const response = await dispatch(reserveSchedule({ reservationData }));

      if (response.status === 201 || response.status === 200) {
        trackReservationAttempt(reservationData, true);
        setReservationStatus("Reservation successful!");
        if (!user) {
          setEmail("");
          setName("");
          setPhone("");
        }
        setDate(null);
        setTime("");
        setRequest("");
        setAgreedToTerms(false);
        setSubmitStatus(true);
      } else if (response.status === 400) {
        trackReservationAttempt(reservationData, false, response.data.message);
        setReservationStatus(response.data.message);
        setSubmitStatus(false);
      }
    } catch (error) {
      // console.error("Error submitting reservation:", error);
      trackReservationAttempt(
        reservationData,
        false,
        error.message || "Unknown error occurred"
      );
      setSubmitStatus(false);
      setReservationStatus("Failed to make reservation. Please try again.");
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setReservationStatus("");
      }, 5000);
    }
  };

  return (
    <div className="min-h-screen">
      <div className="w-full bg-white py-8 px-4 border-b">
        <div className="max-w-4xl mx-auto text-center space-y-3">
          <div className="flex justify-center gap-1 mb-4">
            <div className="w-1 h-1 rounded-full bg-red-500 animate-pulse"></div>
            <div className="w-1 h-1 rounded-full bg-red-400 animate-pulse delay-100"></div>
            <div className="w-1 h-1 rounded-full bg-red-300 animate-pulse delay-200"></div>
          </div>

          <div className="relative w-full">
            <h1 className="text-xl sm:text-2xl md:text-[2rem] leading-tight font-extrabold text-gray-900 px-2 md:px-4">
              Book now for the
            </h1>
            <h2 className="text-xl sm:text-xl md:text-[1.7rem] leading-tight font-bold mb-4 px-2 md:px-4">
              <span className="text-red-500">ultimate wellness experience</span>
            </h2>
            <h1 className="text-xl sm:text-2xl md:text-[2rem] leading-tight font-extrabold text-gray-900 px-2 md:px-4">
              ON US
            </h1>
          </div>

          <div className="relative">
            <p className="text-base sm:text-lg text-gray-700 font-medium px-2">
              at the{" "}
              <span className="text-red-500 font-semibold">
                CERAGEM Fullerton Store
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="sm:h-auto mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-black">Reserve your visit</h1>

        <form onSubmit={handleSubmit} className="space-y-4 mt-11 mb-6">
          {!user && (
            <>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-red-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                />
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-red-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-red-700"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
                  placeholder="123-456-7890"
                  required
                  className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                />
              </div>
            </>
          )}

          <div>
            <label className="block text-sm font-medium text-red-700">
              Store Location
            </label>
            <input
              type="text"
              value="2251 W Malvern Ave, Fullerton, CA 92833"
              disabled
              className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 bg-gray-50 text-gray-700"
            />
          </div>

          <div>
            <div>
              <label className="block text-sm font-medium text-red-700 mb-2">
                Service
              </label>
              <div className="flex space-x-4 mt-3">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    value="Health Consulting"
                    checked={true}
                    className="form-radio h-5 w-5 text-red-600"
                    required
                  />
                  <span className="text-gray-700">Health Consulting</span>
                </label>
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-red-700"
            >
              Select Date
            </label>
            <div className="relative">
              <DatePicker
                selected={date}
                onChange={handleDateChange}
                includeDates={availableDates}
                dateFormat="MMMM d, yyyy"
                className="mt-1 block w-full py-3 pl-3 pr-10 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                wrapperClassName="w-full"
                required
                placeholderText="Select an available date"
              />
              <FaCalendarAlt className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>

          <div>
            <label
              htmlFor="time"
              className="block text-sm font-medium text-red-700"
            >
              Select Time
            </label>
            <select
              id="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
              className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none appearance-none"
              disabled={!date}
            >
              <option value="">Choose a time</option>
              {availableTimes.map((t) => (
                <option key={t} value={t}>
                  {convertTo12Hour(t)}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="request"
              className="block text-sm font-medium text-red-700"
            >
              Special Requests or Comments
            </label>
            <textarea
              id="request"
              value={request}
              onChange={(e) => setRequest(e.target.value)}
              placeholder="Any special requests or comments for your reservation?"
              rows="3"
              className="mt-1 p-3 block w-full rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
            />
          </div>

          <div className="flex items-start space-x-2">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
                required
                className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
              />
            </div>
            <div className="ml-2 text-sm">
              <label htmlFor="terms" className="font-medium text-gray-700">
                I have read and agree to the{" "}
                <button
                  type="button"
                  onClick={openTerms}
                  className="text-red-600 hover:text-red-500 underline"
                >
                  terms and conditions
                </button>
              </label>
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            onClick={() => trackButtonClick("Submit Reservation")}
            className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300 ease-in-out disabled:opacity-75 disabled:cursor-not-allowed relative h-[42px]"
          >
            <div className="absolute inset-0 flex items-center justify-center">
              {isSubmitting ? (
                <div className="flex gap-1">
                  <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce"></div>
                  <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce [animation-delay:0.2s]"></div>
                  <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce [animation-delay:0.4s]"></div>
                </div>
              ) : (
                "Confirm Reservation"
              )}
            </div>
          </button>
        </form>
        {reservationStatus && (
          <p
            className={`mt-4 ${
              submitStatus ? "text-green-600" : "text-red-600"
            } font-semibold text-center`}
          >
            {reservationStatus}
          </p>
        )}
        <button
          onClick={() => navigate("/home")}
          className="mt-4 text-black hover:text-slate-600 underline"
        >
          Go to MainPage
        </button>
      </div>
    </div>
  );
};

export default FullertonReservation;

import React, { useState } from "react";
import { Mail, X } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { sendEmailAction, toggleContactButton } from "../actions/authActions";

const FloatingContactButton = () => {
  // const [showContactModal, setShowContactModal] = useState(false);
  const showContactModal = useSelector((state) => state.auth.isContactButtonVisible)
  const [contactStatus, setContactStatus] = useState("");
  // const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setContactStatus("Sending...");
    try {
      const result = await dispatch(
        sendEmailAction({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          to: "andy.jeong@ceragemus.com",
        })
      );

      if (result === 200) {
        setContactStatus("Email sent successfully!");
        setFormData({
          email: "",
          name: "",
          message: "",
        });
      } else {
        setContactStatus("Email sent failed");
      }

      setTimeout(()=> setContactStatus(""), 5000);
    } catch (error) {
      setContactStatus("Failed to send email. Please try again.");
      setTimeout(()=> setContactStatus(""), 5000);
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          dispatch(toggleContactButton());
        }}
        style={{ zIndex: 1002, left: "3%", bottom: "12%" }}
        className="fixed border-2 bg-blue-500 text-white rounded-full p-3 shadow-lg hover:bg-blue-600 hover:animate-none hover:scale-110 transition-transform duration-300"
      >
        <Mail size={32} />
      </button>

      {showContactModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          style={{ zIndex: 1001 }}
        >
          <div className="bg-white p-7 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => { dispatch(toggleContactButton());}}
              className="absolute top-2 right-2 text-black hover:text-gray-700"
            >
              <X size={24} />
            </button>
            <h2 className="text-xl font-bold mb-4">Contact Customer Support </h2>
            <form onSubmit={handleContactSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Your Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                ></textarea>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => {dispatch(toggleContactButton());}}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Send Email
                </button>
              </div>
            </form>
            {contactStatus && (
              <p className="mt-4 text-center text-sm font-medium text-green-600">
                {contactStatus}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingContactButton;

import axios from "axios";
import { setAccessToken, getAccessToken, clearAccessToken } from "../auth/authManager";

const API_URL = process.env.NODE_ENV === 'production'
  ? '' // Empty string for relative URLs in production
  : 'http://localhost:5005';

const api = axios.create({
  baseURL: '/api',
  withCredentials: true,
  // cors 설정으로 다른 출처 접근 허용했음, 다른 도메인에 api 요청 보낼 떄 이거 true하면 쿠키가 포함된 요청을 보낼 수 있는거임.
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.request.use(
  // success
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["x-auth-token"] = token;
    }

    // else {
    //   delete config.headers["x-auth-token"];
    //   // Bearer이 oauth, 우리는 x-auth-token
    // }

    return config;
  },

  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["x-auth-token"] = token;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const res = await api.post("/auth/token/refresh");
        const { token } = res.data;
        setAccessToken(token);
        // api.defaults.headers.common["x-auth-token"] = token;
        processQueue(null, token);
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        clearAccessToken();
        // Redirect to login or dispatch logout action
        window.location = "/login"; // or use your routing method
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);


export const getProfile = () => {
  api.get("/profile");
};

export default api;
